import React, { useState } from "react";
import { FormattedMessage } from "react-intl";
import InputContainer from "../common/InputContainer/InputContainer";
import CommonButton from "../common/CommonButton/CommonButton";
import { useSelector, useDispatch } from "react-redux";
import {getMacros, editNickName, filterUlpoadsByUser } from "../../actions";
import ReactGA from 'react-ga';

const EditNickNameModal = ({ openEditUserModal }) => {
  const nickName = useSelector(state => state.user.user_nickname);
  const email = useSelector(state => state.user.user_email);
  const token = useSelector(state => state.user.userId);
  const [userName, setUserName] = useState(nickName);
  const [errorFlag, setErrorFlag] = useState(false);
  const [errorMsg, setErrorMsg] = useState(false);
  const dispatch = useDispatch();
  const category = useSelector(state => state.apps.category);
  const sortBy = useSelector(state => state.apps.sort_by);
  const setNickName = event => {
    event.preventDefault();
    dispatch(editNickName(userName, email, token)).then((res) => {
      if(res.response.status === 'fail') {
        setErrorFlag(true);
        let msg = '';
        res.response.message.message ? msg= res.response.message.message : msg = 'SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN';
        setErrorMsg(msg)
      } else{
        ReactGA.event({
          category: category,
          action: "nickname edited",
          label: "edit nickname"
        });
        dispatch(getMacros(email, null, null, sortBy, null, true, true, email)).then(()=> 
        // dispatch(filterUlpoadsByUser(userName)),
         openEditUserModal(false));
      }
    });
  };

  return (
    <form onSubmit={setNickName}>
      <div
        className="modalContainer"
        onClick={() => openEditUserModal(false)}
      ></div>
      <div className="modalBody">
        <p className="flex vrtlCenter spaceBetween margin-bottom-20">
          <span className="font20 bold darkBluetext">
            <FormattedMessage id="yourBluestacksProfile" />
          </span>
          <span
            className="crossIcon pointer"
            onClick={() => openEditUserModal(false)}
          />
        </p>
        <p className="orangeText font16 margin-bottom-20">
          <FormattedMessage id="thisIsYour" />
        </p>
        <InputContainer
          type="text"
          className={errorFlag ? "inputField margin-bottom-20 errorClass" : "inputField margin-bottom-20"}
          onChange={event => {setErrorFlag(false);setErrorMsg('');setUserName(event.target.value)}}
          label="username"
          value={userName}
          required={true}
          maxLength={16}
          minLength={3}
        />
        {errorFlag && errorMsg &&
          <div className="warningText margin-bottom-10">
            <FormattedMessage id = {errorMsg} />
          </div>
        }
        <CommonButton
          className="saveChangesBtn primaryBtn font16 bold flex pushRight"
          text="saveChanges"
        />
      </div>
    </form>
  );
};

export default EditNickNameModal;
