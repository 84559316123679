import React, { useEffect, useState, useRef } from "react";
import { FormattedMessage, injectIntl } from "react-intl";
import Label from "../common/Label/Label";
import UploadFileSection from "./UploadFileSection";
import { useHistory, useLocation } from "react-router-dom";
import { uploadMacroFile, getGames } from "../../actions";
import { useDispatch, useSelector } from "react-redux";
import EditNickNameModal from "./EditNickNameModal";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import Loader from "../common/Loader/Loader";
import "../../sass/utilities/_material.scss";
import { FormHelperText } from "@material-ui/core";
import ReactGA from "react-ga";

const ITEM_HEIGHT = 52;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 230
    }
  },

  getContentAnchorEl: null,
  anchorOrigin: {
    vertical: "bottom",
    horizontal: "left"
  }
};

const UploadMacro = ({ showHeader, intl }) => {
  const [selectedMacro, selectMacro] = useState("");
  const [installedApps, userInstalledApps] = useState(undefined);
  const [bsMacros, setBsMacros] = useState("");
  const [selectedMacroFile, setSelectedMacroFile] = useState("");
  const location = useLocation();
  const { state: { activeMacro } = {} } = location;
  const [title, setTitle] = useState(
    activeMacro ? activeMacro.content_name : ""
  );
  const [description, setDescription] = useState(
    activeMacro ? activeMacro.content_description : ""
  );
  const [tags, setTags] = useState(activeMacro ? activeMacro.content_tags : "");
  const [showEditModal, openEditUserModal] = useState(false);
  const [packageName, setPackageName] = useState(
    activeMacro ? activeMacro.app_pkg : ""
  );
  const [game, setGame] = useState(activeMacro ? activeMacro.game_name : "");
  const dispatch = useDispatch();
  const history = useHistory();
  const nickName = useSelector(state => state.user.user_nickname);
  const email = useSelector(state => state.user.user_email);
  const loader = useSelector(state => state.apps.loader);
  const [macroSource, setMacroSource] = useState(
    intl.formatMessage({ id: "chooseScript" })
  );
  const [macroGame, setMacroGame] = useState(
    activeMacro
      ? activeMacro.game_name
      : intl.formatMessage({ id: "chooseGame" })
  );
  const [hasGameError, setGameError] = useState(false);
  const [hasSourceError, setSourceError] = useState(false);
  const [titleError, setTitleError] = useState(false);
  const [descriptionError, setDescriptionError] = useState(false);
  const [titleLengthError, setTitleLengthError] = useState(false);
  const [descriptionLengthError, setDescriptionLengthError] = useState(false);
  const [searchTagsError, setSearchTagsError] = useState(false);
  const [fileName, handleFileName] = useState(
    intl.formatMessage({ id: "noFileChosen" })
  );
  const category = useSelector(state => state.apps.category);
  const [subscribed, subscribeToNotifications] = useState(
    activeMacro ? activeMacro.is_subscribed : true
  );
  const [errorMsg, setErrorMsg] = useState(false);

  const handleSubscribeCheckbox = () => subscribeToNotifications(!subscribed);

  const submitMacro = event => {
    event.preventDefault();
    setErrorMsg(false);
    if (!activeMacro) {
      if (!selectedMacroFile) setSourceError(true);
      if (!game) setGameError(true);
      if (!title.trim()) setTitleError(true);
      else if (title && title.length > 50) setTitleLengthError(true);
      if (!description.trim()) setDescriptionError(true);
      else if (description && description.length < 30)
        setDescriptionLengthError(true);
      if (!tags.trim()) setSearchTagsError(true);
      if (
        !selectedMacroFile ||
        !game ||
        !title ||
        !description ||
        !tags ||
        description.length < 30 ||
        title.length > 50
      ) {
        return;
      }
    }

    let content_id;
    if (activeMacro) {
      if (!title.trim()) setTitleError(true);
      else if (title && title.length > 50) setTitleLengthError(true);
      if (!description.trim()) setDescriptionError(true);
      else if (description && description.length < 30)
        setDescriptionLengthError(true);
      if (!tags.trim()) setSearchTagsError(true);
      if (
        !title ||
        !description ||
        !tags ||
        description.length < 30 ||
        title.length > 50
      ) {
        return;
      }
      content_id = activeMacro.content_id;
    }
    dispatch(
      uploadMacroFile(
        email,
        title,
        description,
        tags,
        selectedMacroFile,
        content_id,
        packageName,
        game,
        nickName,
        subscribed
      )
    ).then(res => {
      if (res.errorMsg) {
        let msg = "SOMETHING_WENT_WRONG_PLEASE_TRY_AGAIN";
        setErrorMsg(msg);
      } else {
        history.push({
          pathname: "/myUploads",
          state: { showSuccessMessage: true }
        });
        ReactGA.event({
          category: category,
          action: "macro submitted",
          label: "upload macro"
        });
      }
    });
  };

  const removeEscapedChar = str => {
    return str
      .replace(/\n/g, "\\n")
      .replace(/\r/g, "\\r")
      .replace(/\t/g, "\\t");
  };

  const setInstalledApps = e => {
    const installedAppsString = removeEscapedChar(e.detail);
    dispatch(getGames(installedAppsString))
      .then(res => {
        const appsInstalled = res.payload;
        userInstalledApps(appsInstalled);
      })
      .catch(() => {
        userInstalledApps(installedAppsString);
      });
  };

  useEffect(() => {
    showHeader(false);
    let macros = window.GmApi.getCurrentMacros();
    if (macros) {
      macros = macros.split("|");
      setBsMacros(macros);
      selectMacro("bsMacros");
    } else {
      selectMacro("localMacros");
    }
    window.addEventListener("installedAppsSent", setInstalledApps);

    if (!installedApps) {
      window.GmApi.gmGetInstalledAppsJsonforJS();
    }

    return function cleanup() {
      showHeader(true);
      window.removeEventListener("installedAppsSent", setInstalledApps);
    };
  }, [installedApps]);

  const handleFileSelect = file => {
    if (file) {
      handleFileName(file.name);
      var reader = new FileReader();
      reader.addEventListener("load", e => {
        setSelectedMacroFile(e.target.result);
        if (e.target.result) {
          setSourceError(false);
        }
      });

      reader.readAsDataURL(file);
    }
  };

  const getLocalFile = async file => {
    const macroFile = window.GmApi.uploadLocalMacro(file);
    setSelectedMacroFile(macroFile);
    if (macroFile) {
      setSourceError(false);
    }
  };

  const setGameName = value => {
    const args = value.split("_*_");
    setPackageName(args[0]);
    setGame(args[1]);
    if (args[1]) {
      setGameError(false);
    }
  };
  const handleMacroSelect = event => {
    setMacroSource(event.target.value);
    if (event.target.value === "fileUpload") {
      selectMacro("bothMacros");
    } else {
      selectMacro("bsMacros");
      getLocalFile(event.target.value);
    }
  };

  const fileInput = useRef();

  return (
    <div className="uploadMacro">
      <p className="flex spaceBetween hrtlCenter margin-bottom-25">
        <span className="font24 textColorSubHeading bold">
          <FormattedMessage
            id={`${activeMacro ? "editMacro" : "uploadNewMacro"}`}
          />
        </span>
        <span onClick={() => history.goBack()} className="crossIcon pointer" />
      </p>
      <form onSubmit={submitMacro} className="uploadMacroContainer">
        <div className="flex radioBoxContainer margin-bottom-20 start">
          <div className="flex column grow macroSource">
            <Label
              className="font14 mediumGreyColor margin-bottom-10 grow"
              text="fileSource"
            />
            <>
              {(selectedMacro === "bsMacros" ||
                selectedMacro === "bothMacros") && (
                <div className="selectBoxContainer">
                  <Select
                    value={macroSource}
                    MenuProps={MenuProps}
                    className={`bsMacroList font16 textColorHeading ${
                      hasSourceError ? "errorList" : ""
                    }`}
                    onChange={handleMacroSelect}
                  >
                    <MenuItem
                      className="hiddenOption"
                      value={intl.formatMessage({ id: "chooseScript" })}
                    >
                      {intl.formatMessage({ id: "chooseScript" })}
                    </MenuItem>
                    {bsMacros.map(macro => {
                      return (
                        <MenuItem key={macro} value={macro}>
                          {macro}
                        </MenuItem>
                      );
                    })}
                    <hr className="greySeperator" />
                    <MenuItem value="fileUpload">
                      {intl.formatMessage({ id: "uploadFile" })}
                    </MenuItem>
                  </Select>
                  {hasSourceError && (
                    <FormHelperText className="warningText">
                      {intl.formatMessage({ id: "pleaseFillThisField" })}
                    </FormHelperText>
                  )}
                </div>
              )}
              {(selectedMacro === "localMacros" ||
                selectedMacro === "bothMacros") && (
                <>
                  <div className="chooseFile">
                    <input
                      ref={fileInput}
                      type="file"
                      accept=".json"
                      onChange={e => handleFileSelect(e.target.files[0])}
                      id="file"
                      title=""
                      hidden
                    ></input>
                    <input
                      type="button"
                      className={`selectBtn margin-top-10  ${
                        hasSourceError ? "errorList" : ""
                      }`}
                      onClick={() => fileInput.current.click()}
                      value="Choose File"
                    />
                    <label className="margin-top-10">{fileName}</label>
                  </div>
                  {hasSourceError && (
                    <FormHelperText className="warningText">
                      {intl.formatMessage({ id: "pleaseChooseFile" })}
                    </FormHelperText>
                  )}
                </>
              )}
            </>
          </div>
          <div className="flex column grow">
            <Label
              className="font14 mediumGreyColor margin-bottom-10 grow"
              text="installedGames"
            />
            <div className="selectBoxContainer">
              <Select
                disabled={
                  !installedApps || !installedApps.length ? true : false
                }
                value={macroGame}
                MenuProps={MenuProps}
                className={`font16 textColorHeading bsMacroList ${
                  hasGameError ? "errorList" : ""
                } ${!installedApps || !installedApps.length ? "disabled" : ""}`}
                onChange={event => {
                  return (
                    setMacroGame(event.target.value),
                    setGameName(event.target.value)
                  );
                }}
              >
                <MenuItem
                  className="hiddenOption"
                  value={macroGame}
                >
                  {macroGame}
                </MenuItem>
                {installedApps &&
                  installedApps.map(app => {
                    return (
                      <MenuItem
                        key={app.pacakge}
                        value={`${app.package}_*_${app.name}`}
                        selected={game === app.name}
                      >
                        <span
                          dangerouslySetInnerHTML={{ __html: app.name }}
                        ></span>
                      </MenuItem>
                    );
                  })}
              </Select>
              {hasGameError && (
                <FormHelperText className="warningText">
                  {intl.formatMessage({ id: "pleaseFillThisField" })}
                </FormHelperText>
              )}
            </div>
            {(!installedApps || !installedApps.length) && (
              <p className="warningText font14 margin-top-10">
                <FormattedMessage id="noGamesInstalled" />
              </p>
            )}
          </div>
        </div>
        <UploadFileSection
          setTitle={setTitle}
          title={title}
          titleError={titleError}
          setTitleError={setTitleError}
          setDescription={setDescription}
          descriptionError={descriptionError}
          titleLengthError={titleLengthError}
          setTitleLengthError={setTitleLengthError}
          descriptionLengthError={descriptionLengthError}
          setDescriptionLengthError={setDescriptionLengthError}
          setDescriptionError={setDescriptionError}
          description={description}
          setTags={setTags}
          searchTagsError={searchTagsError}
          setSearchTagsError={setSearchTagsError}
          tags={tags}
          openEditUserModal={openEditUserModal}
          subscribed={subscribed}
          handleSubscribeCheckbox={handleSubscribeCheckbox}
          errorMsg={errorMsg}
        />
      </form>
      {showEditModal && (
        <EditNickNameModal openEditUserModal={openEditUserModal} />
      )}
      {loader && <Loader />}
    </div>
  );
};

export default injectIntl(UploadMacro);
